import React from "react";
import Footer from "./footer";
import { Container, Card, Typography, Grid } from "@material-ui/core";

const PrivacyPolicy = ({ phone }) => {
  return (
    <Container maxWidth="false" disableGutters>
      <Card
        elevation={0}
        style={{
          // backgroundColor: "rgb(77 163 250)",
          padding: "5% 0",
        }}
        square
      >
        <Container maxWidth="md">
          {" "}
          <Typography
            variant="h1"
            style={{
              marginRight: "auto",
              fontSize: phone ? null : "8rem",
              paddingRight: "20px",
            }}
          >
            Privacy Policy
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            Last Updated: 01/07/2024
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you for using CleanCrust! This Privacy Policy outlines how we
            collect, use, disclose, and protect the personal information of our
            users. By using our website, you consent to the practices described
            in this policy.
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" gutterBottom>
            CleanCrust is committed to protecting your privacy and ensuring the
            security of your personal information. This policy explains the
            types of information we collect from users and how we use and share
            that information.
          </Typography>
          <Typography variant="h6" gutterBottom>
            2. Types of Information Collected
          </Typography>
          <Typography variant="body1" gutterBottom>
            We collect the following types of personal information from our
            users:
            <ul>
              <li>Name</li>
              <li>Phone number</li>
              <li>Delivery address</li>
              <li>Previous Orders</li>
            </ul>
          </Typography>
          <Typography variant="h6" gutterBottom>
            3. Legal Basis for Processing
          </Typography>
          <Typography variant="body1" gutterBottom>
            We process your personal data based on the following legal bases:
            <ul>
              <li>
                The necessity of processing for the performance of a contract,
                such as processing your orders.
              </li>
              <li>
                Compliance with legal obligations, such as maintaining
                transaction records.
              </li>
              <li>
                Your consent, which you provide by using our website and
                creating a user account.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" gutterBottom>
            4. Use of Personal Information
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may use your personal information for the following purposes:
            <ul>
              <li>Processing and fulfilling your orders</li>
              <li>Providing customer support and responding to inquiries</li>
              <li>
                Sending rewards and promotional content (with your consent)
              </li>
              <li>Administering our referral scheme</li>
              <li>Improving our website and services</li>
            </ul>
          </Typography>
          <Typography variant="h6" gutterBottom>
            5. Data Sharing and Disclosure
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may disclose your personal data: If we sell our business. In
            cases where we are required by law to pass on information or if we
            believe action is necessary for fraud, cyber crime or to protect the
            website, rights, and personal safety of person/s. We may also
            disclose aggregate statistics about visitors to our website
            (customers and sales) in order to describe our services to
            prospective partners (advertisers, sponsors) and other reputable
            third parties and for other lawful purposes, but these statistics
            will include no personally identifiable information. If you are
            concerned about your data, you have the right, subject to the
            payment of a small fee to request access to personal data which may
            hold or process about you.
          </Typography>
          <Typography variant="h6" gutterBottom>
            6. User Rights
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have the right to:
            <ul>
              <li>Access, correct, or delete your personal information</li>
              <li>
                Restrict or object to the processing of your personal
                information
              </li>
              <li>Withdraw your consent at any time</li>
            </ul>
            You can exercise these rights by contacting us using the information
            provided in Section 12.
          </Typography>
          <Typography variant="h6" gutterBottom>
            7. Data Retention
          </Typography>
          <Typography variant="body1" gutterBottom>
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this policy, unless a longer
            retention period is required or permitted by law.
          </Typography>
          <Typography variant="h6" gutterBottom>
            8. Security Measures
          </Typography>
          <Typography variant="body1" gutterBottom>
            We have implemented reasonable security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, or destruction. However, no method of transmission over
            the internet or electronic storage is 100% secure.
          </Typography>
          <Typography variant="h6" gutterBottom>
            9. Cookies and Tracking Technologies
          </Typography>
          <Typography variant="body1" gutterBottom>
            A cookie is a small text file that we store on your device. Our
            website uses cookies to distinguish you from other users of our
            website. Cookies also provide us with information about how this
            website is used so we can keep it as up-to-date, relevant and
            error-free as possible. We currently use the following cookies:
            Strictly necessary cookies
            <ul>
              <li>
                <strong>Strictly necessary cookies </strong>
                These are cookies that are essential to the operation of our
                website.
              </li>
              <li>
                <strong>Analytical/performance cookie </strong>
                These cookies allow us to recognise and count the number of
                visitors to our website.
              </li>
              <li>
                <strong> Functionality cookies </strong>
                These cookies are used to recognise you when you return to our
                website.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" gutterBottom>
            10. Third-Party Websites
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our website may contain links to third-party websites or services
            that are not operated by us. This privacy policy does not cover the
            practices of those third parties. We encourage you to review their
            privacy policies before providing any personal information.
          </Typography>
          <Typography variant="h6" gutterBottom>
            11. Updates to the Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may update this privacy policy from time to time. We will notify
            you of any material changes by posting the updated policy on our
            website or by other means. Your continued use of our website after
            the changes will signify your acceptance of the updated policy.
          </Typography>
          <Typography variant="h6" gutterBottom>
            12. Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have any questions, concerns, or requests regarding this
            privacy policy or the handling of your personal information, please
            contact us using the infomation below.
          </Typography>
        </Container>
      </Card>

      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
