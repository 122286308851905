import React from "react";
import Footer from "./footer";
import { Container, Card, Typography, Grid, Link } from "@material-ui/core";

const TermsAndConditions = ({ phone }) => {
  return (
    <Container maxWidth="false" disableGutters>
      <Card
        elevation={0}
        style={{
          // backgroundColor: "rgb(77 163 250)",
          padding: "5% 0",
        }}
        square
      >
        <Container maxWidth="md">
          <Typography
            variant="h1"
            style={{
              marginRight: "auto",
              fontSize: phone ? null : "8rem",
              paddingRight: "20px",
            }}
          >
            Terms and Conditions
          </Typography>
          <Typography>Last Updated: 01/07/2024</Typography>
          <br />
          <Typography variant="h4" gutterBottom>
            1. About Us
          </Typography>
          <Typography gutterBottom>
            We are CleanCrust ('<strong>Company</strong>', '<strong>we</strong>
            ', '<strong>us</strong>', or '<strong>our</strong>'), a sole
            proprietorship registered in the United Kingdom at Tollerton Mill,
            Newton Road, Tollerton, York, YO61 1QX.
          </Typography>
          <Typography gutterBottom>
            We operate the website {" "}
            <Link href="/" color="default">
              https://www.cleancrust.co.uk
            </Link>
             (the '
            <strong>Site</strong>'), as well as any other related products and
            services that refer or link to these legal terms (the '
            <strong>Legal Terms</strong>') (collectively, the '
            <strong>Services</strong>').
          </Typography>
          <Typography gutterBottom>
            You can reach us at{" "}
            <Link href="mailto:hello@cleancrust.co.uk" color="default">
              hello@cleancrust.co.uk
            </Link>{" "}
            or call us at{" "}
            <Link href="tel:+447753289571" color="default">
              07753 289 571.
            </Link>
          </Typography>
          <Typography gutterBottom>
            These Legal Terms constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ('
            <strong>you</strong>'), and CleanCrust, concerning your access to
            and use of the Services. You agree that by accessing the Services,
            you have read, understood, and agreed to be bound by all of these
            Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
            YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </Typography>
          <Typography gutterBottom>
            Supplemental terms and conditions or documents that may be posted on
            the Services from time to time are hereby expressly incorporated
            herein by reference. We reserve the right, in our sole discretion,
            to make changes or modifications to these Legal Terms from time to
            time. We will alert you about any changes by updating the 'Last
            updated' date of these Legal Terms, and you waive any right to
            receive specific notice of each such change. It is your
            responsibility to periodically review these Legal Terms to stay
            informed of updates. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Legal Terms by your continued use of the Services after the
            date such revised Legal Terms are posted.
          </Typography>
          <Typography gutterBottom>
            The Services are intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register for
            the Services.
          </Typography>
          <Typography gutterBottom>
            We recommend that you print a copy of these Legal Terms for your
            records
          </Typography>
          <br />
          <Typography variant="h4" gutterBottom>
            2. Our Mission
          </Typography>
          <Typography>
            We aim to provide you with the convenience of ordering items for
            pickup or delivery. When you place an order on our website, we
            personally receive it and work to fulfill your request. If you
            choose delivery, CleanCrust will arrange the delivery of your items.
          </Typography>
          <br />
          <Typography variant="h4" gutterBottom>
            3. Accessing Our Service
          </Typography>
          <Typography>
            Access to our website and service is granted temporarily, and we
            reserve the right to modify or withdraw access without notice (see
            below). To use our website and service, you must be 18 years or
            older. We are not liable if our website or service becomes
            unavailable for any reason or at any time. We may restrict access to
            certain parts or the entire website or service to registered users.
            You are responsible for maintaining the confidentiality of your
            login details and any activities conducted under your account. If
            you suspect any misuse of your login information, please contact us
            at{" "}
            <Link href="mailto:hello@cleancrust.co.uk" color="default">
              hello@cleancrust.co.uk
            </Link>{" "}
            immediately. We reserve the right to deactivate your account at any
            time.
          </Typography>
          <br />
          <Typography variant="h4" gutterBottom>
            4. Acceptable Use
          </Typography>
          <Typography>
            You may only use our service for lawful purposes. It is prohibited
            to use our website or service in any way that violates local,
            national, or international laws or regulations. You also agree not
            to access, interfere with, damage, or disrupt any part of our
            website or service, including the networks and equipment used.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            5. Your Account
          </Typography>
          <Typography gutterBottom>
            If you choose to create a CleanCrust account, you will be asked to
            create a password or use another secure login method. It is crucial
            to keep your password or login method confidential and prevent
            unauthorized access to your email or phone. You are responsible for
            any charges resulting from unauthorized use of your account. Please
            note that we are not liable for any other losses you may incur.
          </Typography>
          <Typography gutterBottom>
            You have the option to close your account at any time through our
            website or by contacting us using the provided contact details. In
            cases of suspected unauthorized account usage, we may temporarily or
            permanently suspend your access. We may also close your account if
            we believe you are misusing our service or engaging in fraudulent
            activities. If your account is permanently closed, any remaining
            account credit obtained through legitimate means will be refunded to
            your registered credit card or through a bank transfer.
          </Typography>
          <Typography gutterBottom>
            To safeguard against fraud or illegal activities, CleanCrust may
            request additional verification of your identity, age, or other
            relevant information. You are responsible for promptly providing the
            requested information. Failure to comply or suspected fraudulent
            behavior may result in the temporary suspension or permanent closure
            of your CleanCrust account. We handle your information in accordance
            with our{" "}
            <Link href="/privacypolicy" color="default">
              Privacy Policy
            </Link>{" "}
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            6. Service Availability
          </Typography>
          <Typography>
            CleanCrust's availability can be found on the order page. The
            delivery radius is determined based on the location setup and the
            distance within which we can ensure the best quality of our products
            during delivery.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            7. Orders
          </Typography>
          <Typography>
            To use our service, you must be 18 years or older. By placing an
            order through our application, you confirm that you meet this age
            requirement. When you submit an order via our application, it is
            subject to our acceptance before confirmation. Upon acceptance, we
            will send you a Confirmation Notice, establishing the contract for
            the requested items. You are responsible for payment of all items
            ordered through your account, including delivery charges. You must
            comply with the terms outlined in this agreement, regardless of
            whether the ordered item is intended for someone else. Please note
            that item availability may change. We make efforts to provide
            up-to-date allergen information and prevent contamination. However,
            our facilities are not entirely allergen-free, and
            cross-contamination is possible. If you have allergies, please
            contact us directly to ensure we can accommodate yours.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            8. Delivery
          </Typography>
          <Typography gutterBottom>
            Upon placing an order, you will be given two delivery options: ASAP
            Delivery or Scheduled Delivery. For ASAP Delivery, we will provide
            an estimated delivery time prior to order confirmation. However, we
            will make every effort to deliver your order promptly. It is crucial
            that you are available to receive the delivery from the moment you
            place the order. Alternatively, for Scheduled Delivery, we will
            inform you of the expected delivery time, and you should be ready to
            receive the delivery within fifteen minutes before and after that
            time. Please note that delivery times may vary due to factors such
            as meal preparation, traffic conditions, weather conditions, and
            driver availability.
          </Typography>{" "}
          <Typography gutterBottom>
            Despite our utmost efforts, unforeseen circumstances may occur,
            resulting in a delay in delivering your order. If your order is more
            than 20 minutes late compared to the communicated time during order
            placement and we have not given you the option to cancel, we will
            collaborate with you to address the situation, unless the delay is
            caused by your actions (e.g., providing an incorrect address or not
            being available at the designated delivery location).
          </Typography>{" "}
          <Typography gutterBottom>
            We will deliver the order to the address you provided during the
            order placement. If you need to change the delivery location after
            placing the order, we might accommodate the change if you notify us
            before dispatching the driver, as long as the new address is within
            the same delivery zone as the original address. However, if changing
            the delivery address is not feasible, you have the option to cancel
            the order. Please be aware that if food preparation has already
            started, you will be charged the full price of the item.
            Additionally, if the driver has already been dispatched, a delivery
            charge will also apply.
          </Typography>
          <Typography>
            In the event of a failed delivery due to your actions, you will
            still be responsible for paying for the item and any applicable
            delivery charges. Situations that may result in a failed delivery
            include (but are not limited to):
            <ul>
              <li>
                - Failing to answer the door or respond to the driver's contact
                attempts within a reasonable timeframe, preventing the delivery
                from taking place.
              </li>
              <li>
                - The driver refusing to deliver age-restricted products to you,
                as stated in section 8 of our terms and conditions.
              </li>
            </ul>
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            9. Your Rights in Case of Item Issues
          </Typography>
          <Typography gutterBottom>
            You have the legal right to receive goods that match their
            description, are of satisfactory quality, and meet any specific
            requirements you communicated to us and we agreed upon before you
            placed your order. If you believe that the items you received do not
            meet these legal rights, please inform us. We may request a
            photograph showing the issue if it is visually identifiable. We will
            provide a refund or credit for the affected part of the item,
            including the delivery if the entire order was affected, unless we
            have reasonable cause to believe that the problem occurred after
            delivery.
          </Typography>
          <Typography>
            Before processing your refund or account credit, we may consider
            relevant factors such as the order details, your account history,
            and the events that transpired during delivery.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            10. Age-Restricted and Regulated Products
          </Typography>
          <Typography gutterBottom>
            Products with age restrictions can only be sold and delivered to
            individuals aged 18 or older. By placing an order for an
            age-restricted product, you confirm that you are at least 18 years
            old. CleanCrust enforces an age verification policy, where drivers
            will ask customers who order age-restricted products to provide
            proof that they are 18 or older before completing the delivery. The
            driver may refuse to deliver age-restricted products to any person
            unless they can provide valid photo identification proving their age
            to be 18 or older. We and the driver reserve the right to refuse
            delivery of alcohol to any person who is under the influence of
            alcohol or drugs. Orders for items containing alcohol may only be
            delivered to residential or business addresses. If delivery of any
            age-restricted product is
          </Typography>
          <Typography gutterBottom>
            refused, you will still be charged for the relevant item and for the
            delivery.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            11. Order Cancellation
          </Typography>
          <Typography gutterBottom>
            You can cancel an order without any charge at any time before we
            start preparing the food, which we refer to as a "Started Order." If
            you wish to cancel an order before it becomes a Started Order,
            please contact us immediately through our application. If we confirm
            that the order has not become a Started Order, we will refund your
            payment, excluding any discounts or vouchers applied to the order
            (see Voucher and Account Credit Terms for more details). If you
            cancel an order after it becomes a Started Order, you will be
            charged the full price for the items, and if the driver has been
            dispatched, you will also be charged for the delivery.
          </Typography>
          <Typography gutterBottom>
            CleanCrust reserves the right to cancel an order at any time. You
            will not be charged for any orders cancelled by us, and we will
            reimburse you for any payment already made using the same payment
            method you used for your order. We may also provide account credit
            to compensate for any inconvenience caused.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            12. Prices, Payment, and Offers
          </Typography>
          <Typography gutterBottom>
            By using our service, you confirm that you are using it for
            personal, non-commercial purposes. Prices are subject to change at
            our discretion. We reserve the right to charge a Service Fee for
            providing our services, which may change over time. You will be
            notified of any applicable Service Fee and taxes before making a
            purchase on the checkout page of our application. Changes will not
            affect confirmed orders, unless there is an obvious pricing mistake.
            Additionally, ongoing price changes will not affect orders in
            progress and present in your basket, provided that you complete the
            order within 2 hours of adding items to your basket. If you do not
            complete the order before the 30-minute cutoff, the items will be
            automatically removed from your basket. If an obvious pricing
            mistake occurs, we will inform you as soon as possible, giving you
            the choice to confirm the order at the original price or cancel the
            order without any charge and receive a full refund for any payment
            already made. When CleanCrust makes a delivery, a delivery fee may
            also apply. This will be communicated to you during the order
            process before completing your order.
          </Typography>
          <Typography gutterBottom>
            The total price of your order, including item prices, delivery fees,
            and applicable service fees and taxes, will be clearly stated on the
            checkout page of our application.
          </Typography>
          <Typography gutterBottom>
            Payment for all items and deliveries can be made via Stripe using
            credit or debit cards, or other payment methods provided by
            CleanCrust. Once your order is confirmed, your credit or debit card
            will be authorized, and the total amount will be marked for payment.
            Payment is made directly to CleanCrust. Payment can also be made
            using vouchers or account credit, subject to CleanCrust's Voucher
            and Account Credit Terms.
          </Typography>
          <Typography>
            In some cases, you may have the option to pay in cash directly to
            the driver at the time of delivery. If cash payment is available,
            this will be indicated on our application before placing your order.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            13. Our Responsibility for Loss or Damage Incurred
          </Typography>
          <Typography gutterBottom>
            We are accountable to you for any loss or damage you incur that is a
            direct result of us breaching these Terms or failing to exercise
            reasonable care and skill in providing our service. However, we are
            not liable for any loss or damage that is not reasonably
            foreseeable. Loss or damage is considered "reasonably foreseeable"
            if it is either obvious that it will happen or if you have informed
            us about specific circumstances that may increase the potential loss
            or damage before placing an order.
          </Typography>
          <Typography>
            We do not exclude or limit our responsibility to you for loss or
            damage where it would be unlawful to do so. This includes any
            responsibility for death or personal injury caused by our failure or
            the failure of our employees, agents, or subcontractors to use
            reasonable care and skill, as well as for fraud, fraudulent
            misrepresentation, breach of your legal rights regarding the items,
            or defective items under the Consumer Protection Act 1987. However,
            except for the situations mentioned above, we are not responsible
            for any loss or damage that you suffer due to your own breach of
            these Terms or as a result of IT hardware or software failures,
            excluding failures in our applications.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            14. Intellectual Property Rights
          </Typography>
          <Typography>
            We handle your personal data in accordance with our Privacy Policy,
            which can be found{" "}
            <Link href="/privacypolicy" color="default">
              here
            </Link>{" "}
            .
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            15. Reliance on Posted Information
          </Typography>
          <Typography>
            Commentary and other materials posted on our service are not
            intended to be relied upon as advice. We disclaim all liability and
            responsibility arising from any reliance placed on such materials by
            any visitor to our service or anyone informed about its contents.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            16. Regular Changes to Our Site and Service
          </Typography>
          <Typography>
            We aim to regularly update and improve our site and service, and we
            may change the content at any time. In some cases, we may need to
            suspend access to our site and service temporarily or close them
            indefinitely. Please note that any material on our site or service
            may be out of date at any given time, and we are not obligated to
            update such material.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            17. Our Liability
          </Typography>
          <Typography>
            While we take care in preparing our site and providing our service,
            we will not be held responsible for any errors or omissions in the
            content or for any technical issues you may encounter while using
            our site or service. If inaccuracies are brought to our attention,
            we will make reasonable efforts to correct them. To the extent
            permitted by law, we exclude all liability (whether arising in
            contract, negligence, or otherwise) for any loss or damage incurred
            by you or any third party in connection with our site, service, or
            any linked websites or materials posted on them. However, this
            exclusion does not apply to our liability for death or personal
            injury caused by negligence, fraudulent misrepresentation,
            misrepresentation of a fundamental matter, or any other liability
            that cannot be excluded or limited under applicable law.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            18. Information About You and Your Visits to Our Site and Use of Our
            Service
          </Typography>
          <Typography>
            We collect certain data about you as a result of your use of our
            service. More detailed information can be found in our privacy
            policy.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            19. Links From Our Site
          </Typography>
          <Typography>
            Our site may contain links to other websites and resources provided
            by third parties. These links are provided for informational
            purposes only. We have no control over the content of those sites or
            resources and accept no responsibility for them or any loss or
            damage that may arise from your use of them.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            20. Jurisdiction and Applicable Law
          </Typography>
          <Typography>
            The English courts have jurisdiction over any claims arising from or
            related to your visit to our site or use of our service. These terms
            of use, including any disputes or claims arising out of or in
            connection with them or their subject matter or formation (including
            non-contractual disputes or claims), are governed by and construed
            in accordance with the laws of England and Wales.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            21. Variations
          </Typography>
          <Typography>
            We may revise these terms of use at any time by updating this page.
            You are advised to check this page periodically to stay informed
            about any changes we make.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            22. Data Protection
          </Typography>
          <Typography>
            We handle your personal data in accordance with our{" "}
            <Link href="/privacypolicy">Privacy Policy</Link>.
          </Typography>{" "}
          <br />
          <Typography variant="h4" gutterBottom>
            23. Other Terms
          </Typography>
          <Typography gutterBottom>
            If either party has the right to enforce these Terms against the
            other, that right will not be waived or lost, even if there is a
            delay in enforcement. If a court or authority determines that any
            part of these Terms is illegal or ineffective, the remaining terms
            will remain in force without being affected.
          </Typography>
          <Typography>
            We may modify these Terms from time to time. If any changes affect
            your rights regarding our service, As mentioned in section 21 it is
            your responsibility to check this page from time to time. Changes to
            the Terms will not impact any orders for which we have sent a
            confirmation notice. These Terms are governed by English law, and
            you can bring legal proceedings related to our service in the
            English courts. If you reside in Scotland, you can bring legal
            proceedings regarding the items in either the Scottish or English
            courts. If you reside in Northern Ireland, you can bring legal
            proceedings regarding the items in...
          </Typography>
        </Container>
      </Card>

      <Footer />
    </Container>
  );
};

export default TermsAndConditions;
